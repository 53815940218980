<template>
<div>
    <b-row>
        <b-col md="12">
            <b-row>
                <b-col md="12">
                    <CCard>
                        <CCardHeader color="primario" text-color="white">
                            <i class="fas fa-digital-tachograph fa-md mr-1"></i> <span class="h5"> Registro report stage 1</span>
                        </CCardHeader>
                        <CCardBody>
                            <b-row>
                                <b-col md="12">
                                    <b-tabs v-model="tabIndex" variant="pills">
                                        <validation-observer ref="observer1" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(datosGenerales)">
                                                <b-tab>
                                                    <template slot="title">
                                                        <i class="fas fa-server fa-md"></i> Datos generales
                                                    </template>
                                                    <b-row class="mt-3">
                                                        <b-col md="12">
                                                            <validation-provider name="normas" rules="required" v-slot="{errors}">
                                                                <b-form-group label="Normas:" class="mb-2">
                                                                    <v-select @input="checkNormas" multiple :reduce="listaNormas => listaNormas.idNorma" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosReport.normas.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosReport.normas" :options="listaNormas">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <!-- <b-col md="12" class="mt-2">
                                                            <validation-provider name="cliente" :rules="!isTemplate ? 'required' : ''" v-slot="{errors}">
                                                                <b-form-group label="Cliente:" class="mb-2">
                                                                    <v-select :reduce="listaClientes => listaClientes.idAuditoria" label="razonSocial" placeholder="Seleccione una opción" :class="{'style-valid-select': datosReport.idAuditoria  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosReport.idAuditoria" :options="listaClientes">
                                                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                    </v-select>
                                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col> -->
                                                        <b-col md="12">
                                                            <validation-provider name="" v-slot="validationContext">
                                                                <b-form-group label="" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.datosArray1[0].clienteFirmadoAcuerdo"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="12" class="my-2">
                                                            <span>Nota: Los sistemas de gestión específicos para los que se prepara el plan de auditoría se definen en Criterios de auditoría.</span>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="número de contrato" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                                <b-form-group label="Número de contrato:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el número de contrato" v-model.lazy="datosReport.datosArray1[0].numeroContrato"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="fecha" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                <b-form-group label="Fecha:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="datosReport.datosArray1[0].fecha"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="12" v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                            <validation-provider name="resumen ejecutivo">
                                                                <b-form-group label="Resumen ejecutivo:" class="mb-2">
                                                                    <!-- <b-form-textarea rows="4" max-rows="6" :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.responsabilidadesOrganizacionAuditada"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
                                                                    <quill-editor ref="quillRejec" v-model="datosReport.datosArray1[0].resumenEjecutivo" :options="editorOption">
                                                                    </quill-editor>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="12" class="my-2">
                                                            <span>1. Objetivos de la auditoría de la etapa I</span>
                                                        </b-col>
                                                        <b-col md="12">
                                                            <validation-provider name="1.1. evaluación inicial de la auditoría de la etapa I">
                                                                <b-form-group label="1.1. Evaluación inicial de la auditoría de la etapa I:" class="mb-2">
                                                                    <!-- <b-form-textarea rows="4" max-rows="6" :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.responsabilidadesOrganizacionAuditada"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
                                                                    <quill-editor ref="quillEiae" v-model="datosReport.datosArray1[0].evaluacionInicialAuditoria" :options="editorOption">
                                                                    </quill-editor>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="12" v-if="disabledNorma1 || disabledNorma2 || disabledNorma4">
                                                            <validation-provider name="1.2 objetivos de la auditoría de la etapa I de la evaluación de la recertificación">
                                                                <b-form-group label="1.2 Objetivos de la auditoría de la etapa I de la evaluación de la recertificación:" class="mb-2">
                                                                    <!-- <b-form-textarea rows="4" max-rows="6" :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.responsabilidadesOrganizacionAuditada"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
                                                                    <quill-editor ref="quillOaeer" v-model="datosReport.datosArray1[0].objetivosAuditoriaRecertificacion" :options="editorOption">
                                                                    </quill-editor>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <!-- <b-col md="4" v-if="disabledNorma2 && disabledNorma3">
                                                            <validation-provider name="perfil del cliente" :rules="{}" v-slot="validationContext">
                                                                <b-form-group label="Perfil del cliente:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el perfil del cliente" v-model.lazy="datosReport.perfilCliente"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col> -->
                                                        <b-col md="12" class="my-2">
                                                            <span>Perfil del cliente:</span>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="dirección física del cliente oficina central" v-slot="validationContext">
                                                                <b-form-group label="Dirección física del cliente - Oficina Central:" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la dirección de la oficina central" v-model.lazy="datosReport.datosArray1[0].direccionContactoOC"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="6">
                                                            <validation-provider name="dirección física del cliente planta" v-slot="validationContext">
                                                                <b-form-group label="Dirección física del cliente - Planta (si es diferente a la anterior):" class="mb-2">
                                                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la dirección de la planta" v-model.lazy="datosReport.datosArray1[0].direccionContactoPlanta"></b-form-input>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>
                                                        <b-col md="12">
                                                            <validation-provider name="alcance de la certificación">
                                                                <b-form-group label="Alcance de la certificación:" class="mb-2">
                                                                    <!-- <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese el alcance de la certificación" v-model.lazy="datosReport.alcanceCertificacion"></b-form-textarea>
                                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
                                                                    <quill-editor ref="quillAlc" v-model="datosReport.datosArray1[0].alcanceCertificacion" :options="editorOption">
                                                                    </quill-editor>
                                                                </b-form-group>
                                                            </validation-provider>
                                                        </b-col>

                                                        <b-col md="12" class="mb-2">
                                                            <b-table-simple bordered show-empty mediun responsive outlined>
                                                                <!-- <b-thead>
                                                                    <b-tr>
                                                                        <b-th colspan="2">Procesos no aplicables para el alcance de la certificación:</b-th>
                                                                    </b-tr>
                                                                </b-thead> -->
                                                                <b-tbody>
                                                                    <b-tr>
                                                                        <b-td width="20%">
                                                                            Ubicaciones del sitio del cliente
                                                                        </b-td>
                                                                        <b-td width="80%">
                                                                            <b-row>
                                                                                <b-col md="2">
                                                                                    <b-form-group label="Sitio único:">
                                                                                        <b-form-radio-group small plain id="radio-group-su" v-model.lazy="datosReport.ubicacionesSitioCliente[0].hasSitioUnico" :options="comboSiNo"></b-form-radio-group>
                                                                                    </b-form-group>
                                                                                </b-col>
                                                                                <b-col md="4">
                                                                                    <b-form-group label="Múltiples sitios según IAF MD 1:">
                                                                                        <b-form-radio-group small plain id="radio-group-msiaf" v-model.lazy="datosReport.ubicacionesSitioCliente[0].hasMultipleSitioIAF" :options="comboSiNo"></b-form-radio-group>
                                                                                    </b-form-group>
                                                                                </b-col>
                                                                                <b-col md="6">
                                                                                    <b-form-group label="Número de sitios múltiples Oficina (s) regional (es):" class="mb-2">
                                                                                        <b-form-input placeholder="Ingrese el número" type="number" v-model.lazy="datosReport.ubicacionesSitioCliente[0].numeroOficinaRegional"></b-form-input>
                                                                                    </b-form-group>
                                                                                </b-col>
                                                                                <b-col md="6">
                                                                                    <b-form-group label="Número de sitios múltiples Oficina (s) sucursal (es):" class="mb-2">
                                                                                        <b-form-input placeholder="Ingrese el número" type="number" v-model.lazy="datosReport.ubicacionesSitioCliente[0].numeroOficinaSucursal"></b-form-input>
                                                                                    </b-form-group>
                                                                                </b-col>
                                                                                <b-col md="4">
                                                                                    <b-form-group label="Sitios de proyectos temporales:">
                                                                                        <b-form-radio-group small plain id="radio-group-spt" v-model.lazy="datosReport.ubicacionesSitioCliente[0].sitiosProyectos" :options="[{value: 1, text: 'Aplicable'}, {value: 2, text: 'No aplica'}]"></b-form-radio-group>
                                                                                    </b-form-group>
                                                                                </b-col>
                                                                                <b-col md="5">
                                                                                    <b-form-group label="Tiene número de sitios de proyectos activos:">
                                                                                        <b-form-radio-group small plain id="radio-group-nspa" v-model.lazy="datosReport.ubicacionesSitioCliente[0].hasNumeroSitios" :options="comboSiNo"></b-form-radio-group>
                                                                                    </b-form-group>
                                                                                </b-col>
                                                                                <b-col md="7" v-if="datosReport.ubicacionesSitioCliente[0].hasNumeroSitios==1">
                                                                                    <b-form-group label="Número de sitios de proyectos activos:" class="mb-2">
                                                                                        <b-form-input placeholder="Ingrese el número" type="number" v-model.lazy="datosReport.ubicacionesSitioCliente[0].numeroSitios"></b-form-input>
                                                                                    </b-form-group>
                                                                                </b-col>
                                                                                <b-col md="7">
                                                                                    <b-form-group label="Número de sitios de proyectos temporales muestreados para ser auditados:" class="mb-2">
                                                                                        <b-form-input placeholder="Ingrese el número" type="number" v-model.lazy="datosReport.ubicacionesSitioCliente[0].numeroSitioAuditados"></b-form-input>
                                                                                    </b-form-group>
                                                                                </b-col>
                                                                            </b-row>
                                                                        </b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </b-col>

                                                    </b-row>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button :to="{name: $route.params.eu ? 'ejecutar auditoria' : 'Documentos Stage 1'}" class="mr-2" variant="dark" type="button">
                                                                <i class="fas fa-arrow-left"></i> Volver
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                Siguiente <i class="fas fa-arrow-right"></i>
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(seccion1)">
                                                <b-tab :title-item-class="(datosReport.idReport=='' && !isTemplate) ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog  fa-md"></i> Sección 1
                                                    </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row>

                                                            <b-col md="6" v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                <b-form-group label="Sitio (s) temporal (es) :">
                                                                    <b-form-radio-group small plain id="radio-group-stemp" v-model.lazy="datosReport.datosArray2[0].hasSitioTemporal" :options="[{value: 1, text: 'Si'}, {value: 2, text: 'No'}, {value: 3, text: 'No aplica'}]"></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6" v-if="datosReport.datosArray2[0].hasSitioTemporal==1 && (disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6)">
                                                                <validation-provider name="número de sitio (s) temporal (es)" v-slot="validationContext">
                                                                    <b-form-group label="Número de sitio (s) temporal (es):" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el número" type="number" v-model.lazy="datosReport.datosArray2[0].numeroSitiosTemporales"></b-form-input>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6" v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                <validation-provider name="tipo de auditoría" v-slot="validationContext">
                                                                    <b-form-group label="Tipo de auditoría:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el tipo de auditoría" v-model.lazy="datosReport.datosArray2[0].tipoAuditoria"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="3" v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                <validation-provider name="fecha de plan de auditoría" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                    <b-form-group label="Fecha de plan de auditoría:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="datosReport.datosArray2[0].fechaPlanAuditoria"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6" v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                <validation-provider name="" v-slot="validationContext">
                                                                    <b-form-group label="" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.datosArray2[0].enfoqueAuditoria"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6" v-if="disabledNorma3 || disabledNorma5 || disabledNorma6">
                                                                <validation-provider name="declaración de aplicabilidad" :rules="{ }" v-slot="validationContext">
                                                                    <b-form-group label="Declaración de aplicabilidad (aplicable solo para SGSI):" class="mb-2">
                                                                        <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.datosArray2[0].declaracionAplicabilidad"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6" v-if="disabledNorma3">
                                                                <validation-provider name="Fabricante o comerciante" :rules="{ }" v-slot="validationContext">
                                                                    <b-form-group label="Fabricante o comerciante:" class="mb-2">
                                                                        <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.datosArray2[0].fabricanteComerciante"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6" v-if="disabledNorma3">
                                                                <b-form-group label="Categoría de riesgo:">
                                                                    <b-form-radio-group small plain id="radio-group-catr" v-model.lazy="datosReport.datosArray2[0].categoriaRiesgo" :options="[{value:1,text:'Bajo'},{value:2,text:'Medio'},{value:3,text:'Elevado'}]"></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6" v-if="disabledNorma3">
                                                                <b-form-group label="Clase de dispositivo médico:">
                                                                    <b-form-radio-group stacked small plain id="radio-group-cdmed" v-model.lazy="datosReport.datosArray2[0].claseDispositivoMedico" :options="[{value:1,text:'Dispositivos médicos activos (No implantable)'},{value:2,text:'Dispositivos médicos activos (Implantable)'},{value:3,text:'Dispositivos médicos no activos (No implantable)'},{value:4,text:'Dispositivos médicos de diagnóstico in vitro'},{value:5,text:'Dispositivo médico estéril'},{value:4,text:'Dispositivos que incorporan l Utilización de sustancias / tecnologías específicas'}]"></b-form-radio-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col md="6" v-if="disabledNorma3 || disabledNorma1">
                                                                <validation-provider name="procesos no cubiertos en QMS / MD QMS" :rules="{ }" v-slot="validationContext">
                                                                    <b-form-group label="Procesos no cubiertos en QMS / MD QMS:" class="mb-2">
                                                                        <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.datosArray2[0].procesosNoCubiertos"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6" v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                <validation-provider name="justificación de la no aplicabilidad del (de los) proceso (s)" :rules="{ }" v-slot="validationContext">
                                                                    <b-form-group label="Justificación de la no aplicabilidad del (de los) proceso (s):" class="mb-2">
                                                                        <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.datosArray2[0].justificacionNoAplicabilidad"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6" v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                <validation-provider name="procesos de servicio en la planta" :rules="{ }" v-slot="validationContext">
                                                                    <b-form-group label="Procesos de servicio en la planta:" class="mb-2">
                                                                        <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.datosArray2[0].procesosServicioPlanta"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6" v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                <validation-provider name="número de sitios temporales activos disponibles que cubren el alcance de la certificación" :rules="{ }" v-slot="validationContext">
                                                                    <b-form-group label="Número de sitios temporales activos disponibles que cubren el alcance de la certificación:" class="mb-2">
                                                                        <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.datosArray2[0].numeroSitiosTemporalesCubren"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6" v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                <validation-provider name="procesos / Servicio / actividades realizadas en cada sitio temporal" :rules="{ }" v-slot="validationContext">
                                                                    <b-form-group label="Procesos / Servicio / actividades realizadas en cada sitio temporal:" class="mb-2">
                                                                        <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.datosArray2[0].procesoServicioActividades"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6" v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                <validation-provider name="proporcionar detalles de los sitios temporales muestreados para la auditoría de la etapa II" :rules="{ }" v-slot="validationContext">
                                                                    <b-form-group label="Proporcionar detalles de los sitios temporales muestreados para la auditoría de la etapa II:" class="mb-2">
                                                                        <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.datosArray2[0].proporcionarDetalleSitios"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12" class="my-2">
                                                                <span>Procesos para el alcance de la certificación:</span>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="oficina central" v-slot="validationContext">
                                                                    <b-form-group label="Oficina central:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la oficina central" v-model.lazy="datosReport.datosArray2[0].oficinaCentral"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="planta" v-slot="validationContext">
                                                                    <b-form-group label="Planta:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la planta" v-model.lazy="datosReport.datosArray2[0].planta"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="oficina regional" v-slot="validationContext">
                                                                    <b-form-group label="Oficina regional:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la oficina regional" v-model.lazy="datosReport.datosArray2[0].oficinaRegional"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="Sucursal" v-slot="validationContext">
                                                                    <b-form-group label="Sucursal:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la sucursal" v-model.lazy="datosReport.datosArray2[0].sucursal"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12" class="my-2">
                                                                <span>Sitio del proyecto temporal:</span>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="alcance de los servicios" v-slot="validationContext">
                                                                    <b-form-group label="Alcance de los servicios:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la sucursal" v-model.lazy="datosReport.datosArray2[0].alcanceServicios"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="procesos" v-slot="validationContext">
                                                                    <b-form-group label="Procesos:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la sucursal" v-model.lazy="datosReport.datosArray2[0].procesos"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(seccion2)">
                                                <b-tab :title-item-class="(datosReport.idReport=='' && !isTemplate) ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog  fa-md"></i> Sección 2
                                                    </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row>

                                                            <b-col md="12" class="mb-2">
                                                                <b-table-simple bordered show-empty small responsive outlined>
                                                                    <b-thead>
                                                                        <b-tr>
                                                                            <b-th colspan="8">Planificación de auditoría:</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr>
                                                                            <b-td colspan="2" rowspan="2">
                                                                                Ubicaciones de auditoría
                                                                            </b-td>
                                                                            <b-td rowspan="2" class="text-center">
                                                                                Oficina central
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].oficinaCentral" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td rowspan="2" class="text-center">
                                                                                Planta
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].planta" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td rowspan="2" class="text-center">
                                                                                Sitio (s) temporal (es)
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].sitioTemporal" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td colspan="3" class="text-center">
                                                                                Varias plantas / oficinas
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td class="text-center">
                                                                                Plantas
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].plantas" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                Oficinas regionales
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].oficianasRegionales" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                Sucursales
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].sucursales" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td width="10%" rowspan="5">
                                                                                Metodología de auditoría
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td width="15%">
                                                                                a. En el sitio
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].enElSitio1" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].enElSitio2" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].enElSitio3" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].enElSitio4" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].enElSitio5" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].enElSitio6" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td width="15%">
                                                                                b. Fuera del sitio (revisión de documentos)
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].fueraSitioRevision1" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].fueraSitioRevision2" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].fueraSitioRevision3" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].fueraSitioRevision4" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].fueraSitioRevision5" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].fueraSitioRevision6" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td width="15%">
                                                                                c. Fuera del sitio (auditoría remota)
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].fueraSitioRemota1" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].fueraSitioRemota2" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].fueraSitioRemota3" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].fueraSitioRemota4" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].fueraSitioRemota5" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].fueraSitioRemota6" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td width="15%">
                                                                                d. Remoto + en el sitio
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].remotoSitio1" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].remotoSitio2" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].remotoSitio3" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].remotoSitio4" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].remotoSitio5" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.planificacionAuditoria[0].remotoSitio6" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="La auditoría actual es" :rules="!isTemplate ? 'required' : ''" v-slot="{errors}">
                                                                    <b-form-group label="La auditoría actual es:" class="mb-2">
                                                                        <v-select multiple :reduce="listaAuditoriasActual => listaAuditoriasActual.idAuditoriaActual" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosReport.datosArray2[0].auditoriaActual.length>0, 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosReport.datosArray2[0].auditoriaActual" :options="listaAuditoriasActual">
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="acreditación" :rules="!isTemplate ? 'required' : ''" v-slot="{errors}">
                                                                    <b-form-group label="Acreditación:" class="mb-2">
                                                                        <v-select :reduce="listaCuerpoAcreditacion => listaCuerpoAcreditacion.idCuerpoAcreditacion" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosReport.datosArray3[0].acreditacion.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosReport.datosArray3[0].acreditacion" :options="listaCuerpoAcreditacion">
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12" class="my-2">
                                                                <span>Área técnica:</span>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="subdivisión" v-slot="validationContext">
                                                                    <b-form-group label="Subdivisión:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la subdivisión" v-model.lazy="datosReport.datosArray3[0].subdivision"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="división" v-slot="validationContext">
                                                                    <b-form-group label="División:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la división" v-model.lazy="datosReport.datosArray3[0].division"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="grupo" v-slot="validationContext">
                                                                    <b-form-group label="Grupo:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el grupo" v-model.lazy="datosReport.datosArray3[0].grupo"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="clase" v-slot="validationContext">
                                                                    <b-form-group label="Clase:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la clase" v-model.lazy="datosReport.datosArray3[0].clase"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <validation-provider name="se emitirá un COC para incluir" :rules="!isTemplate ? 'required' : ''" v-slot="{errors}">
                                                                    <b-form-group label="Se emitirá un COC para incluir:" class="mb-2">
                                                                        <v-select multiple :reduce="listaEmitiraCoc => listaEmitiraCoc.idEmitiraCoc" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosReport.datosArray3[0].emitiraCoc.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosReport.datosArray3[0].emitiraCoc" :options="listaEmitiraCoc">
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12" class="my-2">
                                                                <span>Realización de auditoría:</span>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="Reunión de apertura en" :rules="!isTemplate ? 'required' : ''" v-slot="{errors}">
                                                                    <b-form-group label="Reunión de apertura en:" class="mb-2">
                                                                        <v-select multiple :reduce="listaAperturaClausura => listaAperturaClausura.idApertura" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosReport.datosArray3[0].aperturaAuditoria.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosReport.datosArray3[0].aperturaAuditoria" :options="listaAperturaClausura">
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="fecha de apertura" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                    <b-form-group label="Fecha de apertura:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="datosReport.datosArray3[0].fechaApertura"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="Reunión de clausura en" :rules="!isTemplate ? 'required' : ''" v-slot="{errors}">
                                                                    <b-form-group label="Reunión de clausura en:" class="mb-2">
                                                                        <v-select multiple :reduce="listaAperturaClausura => listaAperturaClausura.idApertura" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': datosReport.datosArray3[0].clausuraAuditoria.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="datosReport.datosArray3[0].clausuraAuditoria" :options="listaAperturaClausura">
                                                                            <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                                        </v-select>
                                                                        <div class="invalid-feedback">{{ errors[0]}}</div>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="4">
                                                                <validation-provider name="fecha de clausura" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                    <b-form-group label="Fecha de clausura:" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" type="date" v-model.lazy="datosReport.datosArray3[0].fechaClausura"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <validation-provider name="criterios de auditoría" v-slot="validationContext">
                                                                    <b-form-group label="Criterios de auditoría:" class="mb-2">
                                                                        <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese los criterios de auditoría" v-model.lazy="datosReport.datosArray3[0].criteriosAuditoria"></b-form-textarea>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12" class="mb-2">
                                                                <b-table-simple bordered show-empty small responsive outlined>
                                                                    <!-- <b-thead>
                                                                    <b-tr>
                                                                        <b-th colspan="8">Planificación de auditoría:</b-th>
                                                                    </b-tr>
                                                                </b-thead> -->
                                                                    <b-tbody>
                                                                        <b-tr>
                                                                            <b-td width="15%">
                                                                                Equipo de auditoría
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-row>
                                                                                    <b-col md="4">
                                                                                        <b-form-group label="TL:" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el TL" v-model.lazy="datosReport.equipoAuditoria[0].tl"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="4">
                                                                                        <b-form-group label="Miembro del equipo:" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el miembro del equipo" v-model.lazy="datosReport.equipoAuditoria[0].miembroEquipo1"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="4">
                                                                                        <b-form-group label="Miembro del equipo:" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el miembro del equipo" v-model.lazy="datosReport.equipoAuditoria[0].miembroEquipo2"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="4">
                                                                                        <b-form-group label="Experto técnico:" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el experto técnico" v-model.lazy="datosReport.equipoAuditoria[0].expertoTecnico"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="4">
                                                                                        <b-form-group label="Observador:" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el observador" v-model.lazy="datosReport.equipoAuditoria[0].observador"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="4">
                                                                                        <b-form-group label="Auditor en formación:" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el auditor en formación" v-model.lazy="datosReport.equipoAuditoria[0].auditorFormacion"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <span>Asistentes de los clientes para la reunión de apertura y cierre:</span>
                                                            </b-col>
                                                            <b-col md="6" class="text-right mb-1">
                                                                <CButton @click="agregarFilaAsistenteCliente()" size="sm" color="dark">
                                                                    <i class="fas fa-plus fa-sm"></i> Agregar asistente
                                                                </CButton>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <b-table :items="datosReport.listaAsistentesClientes" :fields="camposAsistentesClientes" class="table-custom" show-empty small responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                                                    <template v-slot:cell(nombre)="row">
                                                                        <validation-provider :name="'nombre-'+row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                            <b-form-group>
                                                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model.lazy="row.item.nombre"></b-form-input>
                                                                            </b-form-group>
                                                                        </validation-provider>

                                                                    </template>
                                                                    <template v-slot:cell(puesto)="row">
                                                                        <validation-provider :name="'puesto-'+row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                            <b-form-group>
                                                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el puesto" v-model.lazy="row.item.puesto"></b-form-input>
                                                                            </b-form-group>
                                                                        </validation-provider>

                                                                    </template>
                                                                    <template #cell(opciones)="param">
                                                                        <b-button @click="quitarFilaAsistenteCliente(param)" v-if="datosReport.listaAsistentesClientes.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                        </b-button>
                                                                    </template>
                                                                </b-table>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer4" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(seccion3)">
                                                <b-tab :title-item-class="(datosReport.idReport==''  && !isTemplate) ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog  fa-md"></i> Sección 3
                                                    </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row>
                                                            <b-col md="12">
                                                                <validation-provider name="" v-slot="validationContext">
                                                                    <b-form-group label="" class="mb-2">
                                                                        <b-form-input :state="getValidationState(validationContext)" placeholder="Escriba aquí" v-model.lazy="datosReport.representantesIqcCliente"></b-form-input>
                                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                            <b-col md="12" class="mb-2">
                                                                <b-table-simple bordered show-empty small responsive outlined>
                                                                    <b-thead>
                                                                        <b-tr class="text-center">
                                                                            <b-th colspan="5">Resultados de la auditoría de la etapa I (Resultados de la auditoría: indique A para aceptación u OBS para observación)</b-th>
                                                                        </b-tr>
                                                                        <b-tr class="text-center">
                                                                            <b-th>Función responsable</b-th>
                                                                            <b-th>Proceso auditado</b-th>
                                                                            <b-th>Muestra de identificación, evidencia y observación</b-th>
                                                                            <b-th>Aceptar</b-th>
                                                                            <b-th>Observación</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr>
                                                                            <b-td width="15%">
                                                                                Líder del equipo de auditoría
                                                                                <!-- <b-form-group label="Fecha:" class="mb-2">
                                                                                <b-form-input type="date" v-model.lazy="datosReport.datosDetalles[0].fecha"></b-form-input>
                                                                            </b-form-group> -->
                                                                                <b-form-group label="Auditado:" class="mb-2">
                                                                                    <b-form-checkbox-group v-model.lazy="datosReport.resultadosAuditoriaE1[0].auditado1" :options="[{value: 1, text: 'En el sitio'},{value: 2, text: 'Auditoría remota'}]" small plain></b-form-checkbox-group>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td colspan="2">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto1"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td width="6%" class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar1" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td width="8%" class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion1" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                Visita a la planta / oficina
                                                                                <!-- <b-form-group label="Fecha:" class="mb-2">
                                                                                <b-form-input type="date" v-model.lazy="datosReport.datosDetalles[0].fecha"></b-form-input>
                                                                            </b-form-group> -->
                                                                                <b-form-group label="Auditado:" class="mb-2">
                                                                                    <b-form-checkbox-group v-model.lazy="datosReport.resultadosAuditoriaE1[0].auditado2" :options="[{value: 1, text: 'En el sitio'},{value: 2, text: 'Auditoría remota'}]" small plain></b-form-checkbox-group>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td colspan="2">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto2"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar2" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion2" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                Función responsable del Sistema de Gestión
                                                                                <!-- <b-form-group label="Fecha:" class="mb-2">
                                                                                <b-form-input type="date" v-model.lazy="datosReport.datosDetalles[0].fecha"></b-form-input>
                                                                            </b-form-group> -->
                                                                                <b-form-group label="Auditado:" class="mb-2">
                                                                                    <b-form-checkbox-group v-model.lazy="datosReport.resultadosAuditoriaE1[0].auditado3" :options="[{value: 1, text: 'En el sitio'},{value: 2, text: 'Auditoría remota'}]" small plain></b-form-checkbox-group>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td colspan="2">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto3"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar3" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion3" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                Alta dirección
                                                                                <!-- <b-form-group label="Fecha:" class="mb-2">
                                                                                <b-form-input type="date" v-model.lazy="datosReport.datosDetalles[0].fecha"></b-form-input>
                                                                            </b-form-group> -->
                                                                                <b-form-group label="Auditado:" class="mb-2">
                                                                                    <b-form-checkbox-group v-model.lazy="datosReport.resultadosAuditoriaE1[0].auditado4" :options="[{value: 1, text: 'En el sitio'},{value: 2, text: 'Auditoría remota'}]" small plain></b-form-checkbox-group>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td width="35%">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto4"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td width="36%">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto44"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar4" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion4" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td rowspan="10">
                                                                                Representante de la dirección Responsable de la implementación del sistema de gestión
                                                                                <!-- <b-form-group label="Fecha:" class="mb-2">
                                                                                <b-form-input type="date" v-model.lazy="datosReport.datosDetalles[0].fecha"></b-form-input>
                                                                            </b-form-group> -->
                                                                                <b-form-group label="Auditado:" class="mb-2">
                                                                                    <b-form-checkbox-group v-model.lazy="datosReport.resultadosAuditoriaE1[0].auditado5" :options="[{value: 1, text: 'En el sitio'},{value: 2, text: 'Auditoría remota'}]" small plain></b-form-checkbox-group>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto5"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto55"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar5" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion5" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto6"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto66"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar6" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion6" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto77"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto77"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar7" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion7" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto8"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto88"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar8" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion8" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto9"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto99"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar9" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion9" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto10"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto100"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar10" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion10" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto11"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto110"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar11" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion11" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto12"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto120"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar12" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion12" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto13"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto130"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar13" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion13" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto14"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto140"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar14" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion14" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                Reunión de cierre
                                                                                <!-- <b-form-group label="Fecha:" class="mb-2">
                                                                                <b-form-input type="date" v-model.lazy="datosReport.datosDetalles[0].fecha"></b-form-input>
                                                                            </b-form-group> -->
                                                                                <b-form-group label="Auditado:" class="mb-2">
                                                                                    <b-form-checkbox-group v-model.lazy="datosReport.resultadosAuditoriaE1[0].auditado15" :options="[{value: 1, text: 'En el sitio'},{value: 2, text: 'Auditoría remota'}]" small plain></b-form-checkbox-group>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td colspan="2">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resultadosAuditoriaE1[0].texto15"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].aceptar15" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                            <b-td class="text-center">
                                                                                <b-form-checkbox plain v-model="datosReport.resultadosAuditoriaE1[0].observacion15" value="2" unchecked-value="1"></b-form-checkbox>
                                                                            </b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </b-col>

                                                        </b-row>
                                                    </div>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer5" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(seccion4)">
                                                <b-tab :title-item-class="(datosReport.idReport==''  && !isTemplate) ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog  fa-md"></i> Sección 4
                                                    </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row>
                                                            <b-col md="12" class="mb-2">
                                                                <b-table-simple bordered show-empty small responsive outlined>
                                                                    <b-thead>
                                                                        <b-tr class="text-center">
                                                                            <b-th colspan="3">Etapa I Conclusiones y recomendaciones de la auditoría del sistema de gestión</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr>
                                                                            <b-td width="30%">
                                                                                Conclusión de la auditoría de la etapa I: <br> (Tache lo que no sea aplicable)
                                                                            </b-td>
                                                                            <b-td colspan="2">
                                                                                <b-row>
                                                                                    <b-col md="12">
                                                                                        <b-form-checkbox plain v-model="datosReport.conclusionesRecomendacionesE1[0].planAuditoria" value="2" unchecked-value="1">Plan para la auditoría de Etapa II de IA </b-form-checkbox>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-checkbox plain v-model="datosReport.conclusionesRecomendacionesE1[0].planAuditoriaSujeto" value="2" unchecked-value="1">Plan de Auditoría IA-Etapa II sujeto a acciones correctivas para observación (es). </b-form-checkbox>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-checkbox plain v-model="datosReport.conclusionesRecomendacionesE1[0].noRecomendado" value="2" unchecked-value="1">No recomendado para la Etapa II ya que no se han logrado los objetivos de la auditoría de la Etapa I</b-form-checkbox>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="3">
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.conclusionesRecomendacionesE1[0].texto1"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="2">
                                                                                <b-row>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="Representante del cliente:" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el representante del cliente" v-model.lazy="datosReport.conclusionesRecomendacionesE1[0].representanteCliente"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="Firma">
                                                                                            <b-input-group>
                                                                                                <b-input-group-prepend v-if="datosReport.conclusionesRecomendacionesE1[0].urlFirma1 != ''">
                                                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosReport.conclusionesRecomendacionesE1[0].urlFirma1)" v-c-tooltip="'Descargar'">
                                                                                                        <i class="fas fa-download fa-xs"></i>
                                                                                                    </b-button>
                                                                                                </b-input-group-prepend>
                                                                                                <b-form-file ref="fileFirma1" class="text-left" v-model.lazy="datosReport.conclusionesRecomendacionesE1[0].firma1" v-on:change="handleFileUploadFirma1" :placeholder="datosReport.conclusionesRecomendacionesE1[0].nombreFirma1 ? datosReport.conclusionesRecomendacionesE1[0].nombreFirma1 : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosReport.conclusionesRecomendacionesE1[0].nombreFirma1 ? datosReport.conclusionesRecomendacionesE1[0].nombreFirma1 : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>

                                                                                            </b-input-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                </b-row>

                                                                            </b-td>
                                                                            <b-td width="50%">
                                                                                <b-row>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="Líder del equipo de auditoría de IQC:" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el líder del equipo de auditoría" v-model.lazy="datosReport.conclusionesRecomendacionesE1[0].liderEquipo"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="Firma">
                                                                                            <b-input-group>
                                                                                                <b-input-group-prepend v-if="datosReport.conclusionesRecomendacionesE1[0].urlFirma2 != ''">
                                                                                                    <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosReport.conclusionesRecomendacionesE1[0].urlFirma2)" v-c-tooltip="'Descargar'">
                                                                                                        <i class="fas fa-download fa-xs"></i>
                                                                                                    </b-button>
                                                                                                </b-input-group-prepend>
                                                                                                <b-form-file ref="fileFirma2" class="text-left" v-model.lazy="datosReport.conclusionesRecomendacionesE1[0].firma2" v-on:change="handleFileUploadFirma2" :placeholder="datosReport.conclusionesRecomendacionesE1[0].nombreFirma2 ? datosReport.conclusionesRecomendacionesE1[0].nombreFirma2 : 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="datosReport.conclusionesRecomendacionesE1[0].nombreFirma2 ? datosReport.conclusionesRecomendacionesE1[0].nombreFirma2 : 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>

                                                                                            </b-input-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </b-td>
                                                                        </b-tr>
                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </b-col>
                                                            <b-col md="12" class="mb-2" v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                <b-table-simple bordered show-empty small responsive outlined>
                                                                    <!-- <b-thead>
                                                                        <b-tr class="text-center">
                                                                            <b-th>Detalles de la auditoría remota</b-th>
                                                                        </b-tr>
                                                                    </b-thead> -->
                                                                    <b-tbody>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                <b-row>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="Detalles de la auditoría remota:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio1" :options="[{value:1,text:'Aplicable'},{value:2,text:'Parcialmente aplicable'},{value:3,text:'No aplica'}]"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="a.	La auditoría remota se llevó a cabo según el plan de auditoría y el acuerdo con el cliente, que está disponible en el registro para su verificación:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio2" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="b.	¿La calidad de la conexión en línea, el ancho de banda y el hardware disponible en ambos extremos eran adecuados para realizar una auditoría eficiente?:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio3" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="8">
                                                                                        <b-form-group label="c.	Brindar información breve sobre la infraestructura y herramientas para el domicilio social - Planta y sitios temporales:">
                                                                                            <b-form-checkbox-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].check1" :options="[{value:1,text:'Laptop'},{value:2,text:'Skype'},{value:3,text:'WebEx'},{value:4,text:'Zoom'},{value:5,text:'Meet'}]"></b-form-checkbox-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="4">
                                                                                        <b-form-group label="Si son otros especificar:">
                                                                                            <b-form-input placeholder="Escriba aquí" v-model.lazy="datosReport.detalleAuditoriaRemota[0].otrosEspecificar"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="d.	¿Se visualizó el plano de planta: ubicación de la planta antes de la auditoría?:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio4" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="e.	¿IQC proporcionó el enlace o el cliente proporcionó el enlace?">
                                                                                            <b-form-input placeholder="Escriba aquí" v-model.lazy="datosReport.detalleAuditoriaRemota[0].proporcionoEnlace"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="f.	Se comunicó al cliente el tipo de requisito de herramienta de comunicación y se planificó para la infraestructura según lo solicitado:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio5" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="g.	¿Se llevó a cabo la entrevista de auditoría a través de una comunicación interactiva?:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio6" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="6">
                                                                                        <b-form-group label="h.	¿Hubo algún ruido de fondo durante comunicación interactiva?">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio7" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="6">
                                                                                        <b-form-group label="Detalle de medidas tomadas">
                                                                                            <b-form-input placeholder="Escriba aquí" v-model.lazy="datosReport.detalleAuditoriaRemota[0].detalleMedidasTomadas"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="i.	¿Se observó el trabajo realizado en producción, tiendas, calibración y cualquier otra función a través de imágenes en tiempo real como Skype?:">
                                                                                            <b-form-checkbox-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].check2" :options="[{value:1,text:'WebEx'},{value:2,text:'Meet'},{value:3,text:'Zoom'},{value:4,text:'Otros'},{value:5,text:'o grabaciones de video'},{value:6,text:'u otras personas '}]"></b-form-checkbox-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="j.	¿Se tomó alguna captura de pantalla de algún registro durante la realización de la entrevista de auditoría?:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio8" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="¿Se tomó la aprobación del cliente? teniendo en cuenta los asuntos de confidencialidad y seguridad y los registros de aprobación mantenidos?:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio9" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="k.	¿Hubo alguna interrupción de la comunicación durante la entrevista de auditoría?:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio10" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="l.	¿Se cubrieron todos los procesos / actividades / funciones bajo el alcance de la certificación durante el punto de auditoría remoto?:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio11" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12" v-if="datosReport.detalleAuditoriaRemota[0].radio11==2">
                                                                                        <b-form-group label="Especificar los procesos / actividades / funciones que no se cubrieron durante la auditoría remota para lo cual se realizará una auditoría in situ adicional">
                                                                                            <b-form-input placeholder="Escriba aquí" v-model.lazy="datosReport.detalleAuditoriaRemota[0].procesosActividadesFunciones"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="m.	Aprobación del cliente considerando asuntos de confidencialidad y seguridad:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio12" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="n.	Auditoría remota realizada según el plan de auditoría y acuerdo firmado, que está disponible en el registro para verificación:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio13" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="o.	La auditoría se realizó sobre la base de la verificación de documentos electrónicos:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio14" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="p.	La auditoría se llevó a cabo interactuando con el auditado / equipo de auditados en cuestión y también verificando los documentos electrónicamente:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio15" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="TIC utilizadas:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.detalleAuditoriaRemota[0].radio16" :options="comboSiNo"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="Que es:">
                                                                                            <b-form-input placeholder="Escriba aquí" v-model.lazy="datosReport.detalleAuditoriaRemota[0].queEs"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </b-td>
                                                                        </b-tr>

                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <validation-provider name="nivel de integración de sistemas de gestión integrados">
                                                                    <b-form-group label="Nivel de integración de sistemas de gestión integrados:" class="mb-2">
                                                                        <quill-editor ref="quillNisgi" v-model="datosReport.nivelIntegracionSGI" :options="editorOption">
                                                                        </quill-editor>
                                                                    </b-form-group>
                                                                </validation-provider>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-arrow-right"></i> Siguiente
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>

                                        <validation-observer ref="observer5" v-slot="{ handleSubmit }">
                                            <b-form @submit.stop.prevent="handleSubmit(registrarReport)">
                                                <b-tab :title-item-class="(datosReport.idReport==''  && !isTemplate) ? 'disabledTab' : ''">
                                                    <template slot="title">
                                                        <i class="fas fa-cog  fa-md"></i> Sección 5
                                                    </template>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-save"></i> Guardar
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <div class="mt-3">
                                                        <b-row>
                                                            <b-col md="12" class="mb-2">
                                                                <b-table-simple bordered show-empty small responsive outlined>
                                                                    <b-thead>
                                                                        <b-tr class="text-center">
                                                                            <b-th colspan="2">Resumen del informe de auditoría de la etapa I:</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                            <b-td width="20%">
                                                                                Proceso (s) de producción / servicio
                                                                            </b-td>
                                                                            <b-td width="80%">
                                                                                <quill-editor ref="quillPpser" v-model="datosReport.resumenInformeAuditoria[0].procesoProduccionServicio" :options="editorOption">
                                                                                </quill-editor>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td width="20%">
                                                                                Número de empleados según CIC
                                                                            </b-td>
                                                                            <b-td width="80%">
                                                                                <b-row>
                                                                                    <b-col md="3">
                                                                                        <b-form-group label="Directo" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el número" v-model.lazy="datosReport.resumenInformeAuditoria[0].directo1"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="3">
                                                                                        <b-form-group label="Contrato" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el número" v-model.lazy="datosReport.resumenInformeAuditoria[0].contrato1"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="3">
                                                                                        <b-form-group label="estacional" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el número" v-model.lazy="datosReport.resumenInformeAuditoria[0].estacional1"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="3">
                                                                                        <b-form-group label="Total" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el número" v-model.lazy="datosReport.resumenInformeAuditoria[0].total1"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                Número de empleados durante la auditoría de la Etapa I
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-row>
                                                                                    <b-col md="3">
                                                                                        <b-form-group label="Directo" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el número" v-model.lazy="datosReport.resumenInformeAuditoria[0].directo2"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="3">
                                                                                        <b-form-group label="Contrato" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el número" v-model.lazy="datosReport.resumenInformeAuditoria[0].contrato2"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="3">
                                                                                        <b-form-group label="estacional" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el número" v-model.lazy="datosReport.resumenInformeAuditoria[0].estacional2"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="3">
                                                                                        <b-form-group label="Total" class="mb-2">
                                                                                            <b-form-input placeholder="Ingrese el número" v-model.lazy="datosReport.resumenInformeAuditoria[0].total2"></b-form-input>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                            <b-td colspan="2">
                                                                                <b-row>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="Los procesos de producción / servicio de fabricación están operando en turnos:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.resumenInformeAuditoria[0].procesoProducion" :options="[{value:1,text:'Si'},{value:2,text:'No'},{value:3,text:'No aplica'}]"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="Especificar detalles de turno:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.resumenInformeAuditoria[0].especificarDetalle" :options="[{value:1,text:'Turno general'},{value:2,text:'Primer turno'},{value:3,text:'Segundo turno'},{value:3,text:'Tercer turno'}]"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="¿Los servicios son similares en todos los turnos?:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.resumenInformeAuditoria[0].serviciosSimilares" :options="[{value:1,text:'Si'},{value:2,text:'No'},{value:3,text:'No aplica'}]"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                </b-row>

                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                            <b-td colspan="2">
                                                                                <b-row>
                                                                                    <b-col md="12" class="my-1">
                                                                                        <span>Recomendación del auditor para la auditoría de la etapa II: calendario propuesto para el plan de auditoría para la etapa II:</span>
                                                                                    </b-col>
                                                                                    <b-col md="4">
                                                                                        <b-form-group label="Planifique durante el horario laboral normal ya que el cliente trabaja en un solo turno :">
                                                                                            <b-form-checkbox small plain v-model.lazy="datosReport.resumenInformeAuditoria[0].planifiqueHorario" value="2" unchecked-value="1"></b-form-checkbox>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="4">
                                                                                        <b-form-group label="Planifique durante el horario laboral normal, ya que los procesos y las operaciones son similares en cada turno:">
                                                                                            <b-form-checkbox small plain v-model.lazy="datosReport.resumenInformeAuditoria[0].planifiqueHorarioProcesos" value="2" unchecked-value="1"></b-form-checkbox>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="4">
                                                                                        <b-form-group label="Planifique durante el turno de día y parte del turno siguiente considerando diferentes procesos / operaciones en cada turno :">
                                                                                            <b-form-checkbox small plain v-model.lazy="datosReport.resumenInformeAuditoria[0].planifiqueDuranteTurno" value="2" unchecked-value="1"></b-form-checkbox>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                </b-row>

                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr v-if="disabledNorma3 || disabledNorma1 || disabledNorma5 || disabledNorma6">
                                                                            <b-td colspan="2">
                                                                                <b-row>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="El cliente ha aceptado la auditoría en el segundo o tercer turno, si es necesario y planificado:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.resumenInformeAuditoria[0].clienteAceptado" :options="[{value:1,text:'Si'},{value:2,text:'No'},{value:3,text:'No aplica'}]"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="Quejas de los clientes:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.resumenInformeAuditoria[0].quejasClientes" :options="[{value:1,text:'Si'},{value:2,text:'No'},{value:3,text:'No aplica'}]"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="Reclamación de la autoridad legal:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.resumenInformeAuditoria[0].reclamacionAutoridad" :options="[{value:1,text:'Si'},{value:2,text:'No'},{value:3,text:'No aplica'}]"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12">
                                                                                        <b-form-group label="¿Se abordan todos los procesos según el requisito de las cláusulas y subcláusulas estándar del sistema de gestión pertinentes?:">
                                                                                            <b-form-radio-group small plain v-model.lazy="datosReport.resumenInformeAuditoria[0].abordanProcesos" :options="[{value:1,text:'Si'},{value:2,text:'No'}]"></b-form-radio-group>
                                                                                        </b-form-group>
                                                                                    </b-col>
                                                                                    <b-col md="12" class="my-1">
                                                                                        <span>Toda la información / procedimientos documentados y los documentos referidos serán verificados para su implementación durante la etapa II de la auditoría</span>
                                                                                    </b-col>
                                                                                </b-row>

                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td>
                                                                                Operaciones <br>Planificar la auditoría de la Etapa II
                                                                            </b-td>
                                                                            <b-td>
                                                                                <b-form-group label="" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resumenInformeAuditoria[0].operacionesPlanificarAuditoria"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="2">
                                                                                <b-form-group label="El proceso de auditoría fue fluido, sin obstáculos y sin impacto en la imparcialidad: " class="mb-2">
                                                                                    <b-form-radio-group v-model.lazy="datosReport.procesoAuditoriaFluido" :options="[{value: 1, text: 'Si'},{value: 2, text: 'No'}]" small plain></b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="2">
                                                                                <b-form-group label="Breve resumen del cliente:" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resumenInformeAuditoria[0].resumenCliente"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="2">
                                                                                <b-form-group label="Referencia de usuarios de productos / servicios del cliente:" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resumenInformeAuditoria[0].referenciaUsuarios"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="2">
                                                                                <b-form-group label="Requisitos legales aplicables relacionados con el producto:" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resumenInformeAuditoria[0].requisitosLegales"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                        </b-tr>
                                                                        <b-tr>
                                                                            <b-td colspan="2">
                                                                                <b-form-group label="Requisitos legales aplicables relacionados con el negocio:" class="mb-2">
                                                                                    <b-form-textarea rows="2" max-rows="6" placeholder="Escriba aquí" v-model.lazy="datosReport.resumenInformeAuditoria[0].requisitosLegalesNegocio"></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-td>
                                                                        </b-tr>

                                                                    </b-tbody>
                                                                </b-table-simple>
                                                            </b-col>
                                                            <b-col md="6">
                                                                <span>Resumen de la (s) observación (es):</span>
                                                            </b-col>
                                                            <b-col md="6" class="text-right mb-1">
                                                                <CButton @click="agregarFilaObservacion()" size="sm" color="dark">
                                                                    <i class="fas fa-plus fa-sm"></i> Agregar observacion
                                                                </CButton>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <b-table :items="datosReport.listaObservaciones" :fields="camposObservaciones" class="table-custom" show-empty small responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                                    <!-- <template v-slot:cell(index)="data">{{ data.index + 1 }}</template> -->
                                                                    <template v-slot:cell(numeroObservacion)="row">
                                                                        <validation-provider :name="'numero de observación-'+row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                            <b-form-group>
                                                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el número de observacion" v-model.lazy="row.item.numeroObservacion"></b-form-input>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </template>
                                                                    <template v-slot:cell(procesoAuditado)="row">
                                                                        <validation-provider :name="'proceso auditado-'+row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                            <b-form-group>
                                                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el proceso auditado" v-model.lazy="row.item.procesoAuditado"></b-form-input>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </template>
                                                                    <template v-slot:cell(numeroClausula)="row">
                                                                        <validation-provider :name="'número de cláusulas-'+row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                            <b-form-group>
                                                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el número de cláusulas" v-model.lazy="row.item.numeroClausula"></b-form-input>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </template>
                                                                    <template v-slot:cell(descripcion)="row">
                                                                        <validation-provider :name="'descripción-'+row.index" :rules="{ required: !isTemplate }" v-slot="validationContext">
                                                                            <b-form-group>
                                                                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la descripción" v-model.lazy="row.item.descripcion"></b-form-input>
                                                                            </b-form-group>
                                                                        </validation-provider>
                                                                    </template>
                                                                    <template #cell(opciones)="param">
                                                                        <b-button @click="quitarFilaObservacion(param)" v-if="datosReport.listaObservaciones.length >= 2" class="mr-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                                        </b-button>
                                                                    </template>
                                                                </b-table>
                                                            </b-col>
                                                            <b-col md="12">
                                                                <quill-editor ref="quillCriqc" v-model="datosReport.confidencialidad" :options="editorOption">
                                                                </quill-editor>
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                    <b-row class="text-center mt-2">
                                                        <b-col md="12">
                                                            <b-button class="mr-2" variant="dark" @click="anterior()" type="button">
                                                                <i class="fas fa-arrow-left"></i> Anterior
                                                            </b-button>
                                                            <b-button variant="success" type="submit">
                                                                <i class="fas fa-save"></i> Guardar
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                </b-tab>
                                            </b-form>
                                        </validation-observer>
                                    </b-tabs>

                                    <!-- <b-row v-if="$route.params.id" class="text-center mt-2">
                                        <b-col md="12">
                                            <b-button class="mr-2" variant="dark" :to="{name: 'Documentos Stage 1'}" type="button">
                                                <i class="fas fa-arrow-left"></i> Volver
                                            </b-button>
                                        </b-col>
                                    </b-row> -->

                                    <!--  </b-form> -->
                                    <!-- </validation-observer> -->
                                </b-col>
                            </b-row>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import translate from "translate";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
    quillEditor
} from 'vue-quill-editor'

export default {
    components: {
        quillEditor
    },
    data() {
        return {
            isTemplate: true,
            editorOption: {
                placeholder: 'Escriba aqui...',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        /* ['blockquote', 'code-block'], */
                        [{
                            'header': 1
                        }, {
                            'header': 2
                        }],
                        [{
                            'list': 'ordered'
                        }, {
                            'list': 'bullet'
                        }],
                        [{
                            'script': 'sub'
                        }, {
                            'script': 'super'
                        }],
                        [{
                            'indent': '-1'
                        }, {
                            'indent': '+1'
                        }],
                        [{
                            'direction': 'rtl'
                        }],
                        [{
                            'size': ['small', false, 'large', 'huge']
                        }],
                        [{
                            'header': [1, 2, 3, 4, 5, 6, false]
                        }],
                        [{
                            'font': []
                        }],
                        [{
                            'color': []
                        }, {
                            'background': []
                        }],
                        [{
                            'align': []
                        }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ],
                    syntax: {
                        highlight: text => hljs.highlightAuto(text).value
                    }
                }
            },
            datosReport: {
                idReport: '',
                idCliente: null,

                //DatosGenerales
                idAuditoria: null,
                normas: [],

                datosArray1: [{
                    numeroContrato: '',
                    fecha: '',
                    clienteFirmadoAcuerdo: 'El cliente ha firmado un acuerdo de certificación para la evaluación inicial con fecha 24.03.2021',
                    resumenEjecutivo: '',
                    evaluacionInicialAuditoria: '',
                    objetivosAuditoriaRecertificacion: '',
                    direccionContactoOC: '',
                    direccionContactoPlanta: '',
                    alcanceCertificacion: '',
                }],

                ubicacionesSitioCliente: [{
                    hasSitioUnico: 1,
                    hasMultipleSitioIAF: 1,
                    numeroOficinaRegional: '',
                    numeroOficinaSucursal: '',
                    sitiosProyectos: 1,
                    hasNumeroSitios: 1,
                    numeroSitios: '',
                    numeroSitioAuditados: '',
                }],

                datosArray2: [{
                    hasSitioTemporal: 1,
                    numeroSitiosTemporales: '',
                    tipoAuditoria: '',
                    auditoriaActual: [],
                    fechaPlanAuditoria: '',
                    enfoqueAuditoria: '',
                    declaracionAplicabilidad: '',
                    fabricanteComerciante: '',
                    categoriaRiesgo: 1,
                    claseDispositivoMedico: '',
                    procesosNoCubiertos: '',
                    justificacionNoAplicabilidad: '',
                    procesosServicioPlanta: '',
                    numeroSitiosTemporalesCubren: '',
                    procesoServicioActividades: '',
                    proporcionarDetalleSitios: '',
                    oficinaCentral: '',
                    planta: '',
                    oficinaRegional: '',
                    sucursal: '',
                    alcanceServicios: '',
                    procesos: '',
                }],

                planificacionAuditoria: [{
                    oficinaCentral: '',
                    planta: '',
                    sitioTemporal: '',
                    plantas: '',
                    oficianasRegionales: '',
                    sucursales: '',
                    enElSitio1: '',
                    enElSitio2: '',
                    enElSitio3: '',
                    enElSitio4: '',
                    enElSitio5: '',
                    enElSitio6: '',
                    fueraSitioRevision1: '',
                    fueraSitioRevision2: '',
                    fueraSitioRevision3: '',
                    fueraSitioRevision4: '',
                    fueraSitioRevision5: '',
                    fueraSitioRevision6: '',
                    fueraSitioRemota1: '',
                    fueraSitioRemota2: '',
                    fueraSitioRemota3: '',
                    fueraSitioRemota4: '',
                    fueraSitioRemota5: '',
                    fueraSitioRemota6: '',
                    remotoSitio1: '',
                    remotoSitio2: '',
                    remotoSitio3: '',
                    remotoSitio4: '',
                    remotoSitio5: '',
                    remotoSitio6: '',
                }],

                datosArray3: [{
                    acreditacion: '',
                    subdivision: '',
                    division: '',
                    grupo: '',
                    clase: '',
                    emitiraCoc: [],
                    aperturaAuditoria: [],
                    fechaApertura: '',
                    clausuraAuditoria: [],
                    fechaClausura: '',
                    criteriosAuditoria: '',
                }],

                equipoAuditoria: [{
                    tl: '',
                    miembroEquipo1: '',
                    miembroEquipo2: '',
                    expertoTecnico: '',
                    observador: '',
                    auditorFormacion: '',
                }],
                listaAsistentesClientes: [{
                    nombre: '',
                    puesto: '',
                }],

                resultadosAuditoriaE1: [{
                    auditado1: [],
                    auditado2: [],
                    auditado3: [],
                    auditado4: [],
                    auditado5: [],
                    auditado15: [],
                    texto1: '',
                    texto2: '',
                    texto3: '',
                    texto4: '',
                    texto44: '',
                    texto5: '',
                    texto55: '',
                    texto6: '',
                    texto66: '',
                    texto7: '',
                    texto77: '',
                    texto8: '',
                    texto88: '',
                    texto9: '',
                    texto99: '',
                    texto10: '',
                    texto100: '',
                    texto11: '',
                    texto110: '',
                    texto12: '',
                    texto120: '',
                    texto13: '',
                    texto130: '',
                    texto14: '',
                    texto140: '',
                    texto15: '',
                    aceptar1: '',
                    aceptar2: '',
                    aceptar3: '',
                    aceptar4: '',
                    aceptar5: '',
                    aceptar6: '',
                    aceptar7: '',
                    aceptar8: '',
                    aceptar9: '',
                    aceptar10: '',
                    aceptar11: '',
                    aceptar12: '',
                    aceptar13: '',
                    aceptar14: '',
                    aceptar15: '',
                    observacion1: '',
                    observacion2: '',
                    observacion3: '',
                    observacion4: '',
                    observacion5: '',
                    observacion6: '',
                    observacion7: '',
                    observacion8: '',
                    observacion9: '',
                    observacion10: '',
                    observacion11: '',
                    observacion12: '',
                    observacion13: '',
                    observacion14: '',
                    observacion15: '',
                }],

                conclusionesRecomendacionesE1: [{
                    planAuditoria: '',
                    planAuditoriaSujeto: '',
                    noRecomendado: '',
                    texto1: '',
                    representanteCliente: '',
                    urlFirma1: '',
                    nombreFirma1: '',
                    firma1: null,
                    liderEquipo: '',
                    urlFirma2: '',
                    nombreFirma2: '',
                    firma2: null,
                }],

                detalleAuditoriaRemota: [{
                    radio1: 1,
                    radio2: 1,
                    radio3: 1,
                    check1: [],
                    otrosEspecificar: '',
                    radio4: 1,
                    proporcionoEnlace: '',
                    radio5: 1,
                    radio6: 1,
                    radio7: 1,
                    detalleMedidasTomadas: '',
                    check2: [],
                    radio8: 1,
                    radio9: 1,
                    radio10: 1,
                    radio11: 1,
                    procesosActividadesFunciones: '',
                    radio12: 1,
                    radio13: 1,
                    radio14: 1,
                    radio15: 1,
                    radio16: 1,
                    queEs: '',
                }],

                nivelIntegracionSGI: '',

                resumenInformeAuditoria: [{
                    procesoProduccionServicio: '',
                    directo1: '',
                    contrato1: '',
                    estacional1: '',
                    total1: '',
                    directo2: '',
                    contrato2: '',
                    estacional2: '',
                    total2: '',
                    procesoProducion: 1,
                    especificarDetalle: 1,
                    serviciosSimilares: 1,
                    planifiqueHorario: '',
                    planifiqueHorarioProcesos: '',
                    planifiqueDuranteTurno: '',
                    clienteAceptado: 1,
                    quejasClientes: 1,
                    reclamacionAutoridad: 1,
                    abordanProcesos: 1,
                    operacionesPlanificarAuditoria: '',
                    procesoAuditoriaFluido: [],
                    resumenCliente: '',
                    referenciaUsuarios: '',
                    requisitosLegales: '',
                    requisitosLegalesNegocio: '',
                }],

                listaObservaciones: [{
                    numeroObservacion: '',
                    procesoAuditado: '',
                    numeroClausula: '',
                    descripcion: '',
                }],

                confidencialidad: '',
            },

            //Disabled
            disabledNorma1: false,
            disabledNorma2: false,
            disabledNorma3: false,
            disabledNorma4: false,
            disabledNorma5: false,
            disabledNorma6: false,
            //Listas
            listaClientes: [],
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015'
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015'
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018'
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016'
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013'
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011'
                },
            ],

            listaAuditoriasActual: [{
                idAuditoriaActual: 1,
                descripcion: 'Auditoría combinada',
            }, {
                idAuditoriaActual: 2,
                descripcion: 'Auditoría conjunta',
            }, {
                idAuditoriaActual: 3,
                descripcion: 'Auditoría integrada',
            }, {
                idAuditoriaActual: 4,
                descripcion: 'Ninguno',
            }, ],
            listaCuerpoAcreditacion: [{
                    idCuerpoAcreditacion: 1,
                    descripcion: 'NABCB'
                },
                {
                    idCuerpoAcreditacion: 2,
                    descripcion: 'JAS-ANZ'
                },
                {
                    idCuerpoAcreditacion: 3,
                    descripcion: 'IAS'
                },
            ],

            comboSiNo: [{
                    value: 1,
                    text: 'SI'
                },
                {
                    value: 2,
                    text: 'NO'
                }
            ],
            listaEmitiraCoc: [{
                    idEmitiraCoc: 1,
                    descripcion: 'Oficina central'
                },
                {
                    idEmitiraCoc: 2,
                    descripcion: 'Planta (s)'
                },
                {
                    idEmitiraCoc: 3,
                    descripcion: 'Oficinas regionales'
                },
                {
                    idEmitiraCoc: 4,
                    descripcion: 'Sucursales'
                },
                {
                    idEmitiraCoc: 5,
                    descripcion: 'Sitio (s) temporal del proyecto'
                },
            ],
            camposAsistentesClientes: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "puesto",
                    label: "Puesto",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],
            camposObservaciones: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "numeroObservacion",
                    label: "Número de observación",
                    class: "text-center",
                },
                {
                    key: "procesoAuditado",
                    label: "Proceso auditado",
                    class: "text-center",
                },
                {
                    key: "numeroClausula",
                    label: "Números de cláusula (de los sistemas de gestión aplicables)",
                    class: "text-center",
                },
                {
                    key: "descripcion",
                    label: "Descripción de la observación",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],
            listaAperturaClausura: [{
                    idApertura: 1,
                    descripcion: 'Oficina central'
                },
                {
                    idApertura: 2,
                    descripcion: 'Planta'
                },
                {
                    idApertura: 3,
                    descripcion: 'Sitio del proyecto'
                },
            ],
            tabIndex: 0,
        }

    },
    methods: {
        descargarDocumento(url) {
            window.open(url)
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        datosGenerales() {
            this.tabIndex += 1;
        },
        seccion1() {
            this.tabIndex += 1;
        },
        seccion2() {
            this.tabIndex += 1;
        },
        seccion3() {
            this.tabIndex += 1;
        },
        seccion4() {
            this.tabIndex += 1;
        },
        anterior() {
            this.tabIndex -= 1;
        },
        handleFileUploadFirma1(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(pdf|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs[`fileFirma1`].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs[`fileFirma1`].reset();
                    return;
                }
                //this.listaDocumentoEmpresaSucursal.documento = this.$refs.file.files[0];
            }
        },
        handleFileUploadFirma2(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(pdf|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileFirma2"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileFirma2"].reset();
                    return;
                }
                //this.listaDocumentoEmpresaSucursal.documento = this.$refs.file.files[0];
            }
        },

        checkNormas(e) {
            !!e.find(x => x == 1) ? this.disabledNorma1 = true : this.disabledNorma1 = false;
            !!e.find(x => x == 2) ? this.disabledNorma2 = true : this.disabledNorma2 = false;
            !!e.find(x => x == 3) ? this.disabledNorma3 = true : this.disabledNorma3 = false;
            !!e.find(x => x == 4) ? this.disabledNorma4 = true : this.disabledNorma4 = false;
            !!e.find(x => x == 5) ? this.disabledNorma5 = true : this.disabledNorma5 = false;
            !!e.find(x => x == 6) ? this.disabledNorma6 = true : this.disabledNorma6 = false;
        },

        obtenerReport() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/obtener-pack-report-s1", {
                    params: {
                        idReport: me.$route.params.id,
                    }
                })
                .then(function (response) {
                    //me.datosReport = response.data[0];
                    me.datosReport.normas = JSON.parse(response.data[0].normas);
                    me.datosReport.datosArray1 = JSON.parse(response.data[0].datosArray1);
                    me.datosReport.ubicacionesSitioCliente = JSON.parse(response.data[0].ubicacionesSitioCliente);
                    me.datosReport.datosArray2 = JSON.parse(response.data[0].datosArray2);
                    me.datosReport.planificacionAuditoria = JSON.parse(response.data[0].planificacionAuditoria);
                    me.datosReport.datosArray3 = JSON.parse(response.data[0].datosArray3);
                    me.datosReport.equipoAuditoria = JSON.parse(response.data[0].equipoAuditoria);
                    me.datosReport.listaAsistentesClientes = JSON.parse(response.data[0].listaAsistentesClientes);
                    me.datosReport.resultadosAuditoriaE1 = JSON.parse(response.data[0].resultadosAuditoriaE1);
                    me.datosReport.conclusionesRecomendacionesE1 = JSON.parse(response.data[0].conclusionesRecomendacionesE1);
                    me.datosReport.nivelIntegracionSGI = response.data[0].nivelIntegracionSGI;
                    me.datosReport.detalleAuditoriaRemota = JSON.parse(response.data[0].detalleAuditoriaRemota);
                    me.datosReport.resumenInformeAuditoria = JSON.parse(response.data[0].resumenInformeAuditoria);
                    me.datosReport.listaObservaciones = JSON.parse(response.data[0].listaObservaciones);
                    me.datosReport.confidencialidad = response.data[0].confidencialidad;
                    me.datosReport.estado = response.data[0].estado;
                    me.datosReport.idReport = response.data[0].idReport;
                    me.datosReport.idCliente = response.data[0].idCliente;
                    me.datosReport.idAuditoria = response.data[0].idAuditoria;

                    !!me.datosReport.normas.find(x => x == 1) ? me.disabledNorma1 = true : me.disabledNorma1 = false;
                    !!me.datosReport.normas.find(x => x == 2) ? me.disabledNorma2 = true : me.disabledNorma2 = false;
                    !!me.datosReport.normas.find(x => x == 3) ? me.disabledNorma3 = true : me.disabledNorma3 = false;
                    !!me.datosReport.normas.find(x => x == 4) ? me.disabledNorma4 = true : me.disabledNorma4 = false;
                    !!me.datosReport.normas.find(x => x == 5) ? me.disabledNorma5 = true : me.disabledNorma5 = false;
                    !!me.datosReport.normas.find(x => x == 6) ? me.disabledNorma6 = true : me.disabledNorma6 = false;

                    if (me.$route.params.eu && me.$route.query.idAuditoria) {
                        me.datosReport.isPack = true;
                        me.datosReport.idReport = me.$route.params.eu == 'u' ? me.$route.params.id : null;
                        me.datosReport.idAuditoria = me.$route.query.idAuditoria || null
                        me.datosReport.idUsuario = me.$store.state.user.uid;
                        me.datosReport.sUsuario = me.$store.state.user.username;
                        me.datosReport.eu = me.$route.params.eu == 'u' ? 'u' : 'i'
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 1',
                    });
                });
        },
        registrarReport() {

            let me = this;
            me.disabled = true;

            const formData = new FormData();

            formData.append("datosReport", JSON.stringify(me.datosReport));
            formData.append("idCliente", me.datosReport.idCliente);
            formData.append("filey1", me.datosReport.conclusionesRecomendacionesE1[0].firma1);
            formData.append("filey2", me.datosReport.conclusionesRecomendacionesE1[0].firma2);
            formData.append("folder", 'pack/documentos-stage-1/gestion-report');

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/registrar-pack-report-s1",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 1',
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });

        },

        traducir(texto) {
            return translate(texto, {
                from: "es",
                to: "en",
                engine: "google",
                key: "AIzaSyBfEPrAhMQ9wHfGhGU6B_N0uB_YyKlfK94",
                cache: 0
            });
        },
        agregarFilaAsistenteCliente() {
            this.datosReport.listaAsistentesClientes.push({
                nombre: '',
                puesto: '',
            })
        },
        quitarFilaAsistenteCliente(param) {
            let me = this;
            me.datosReport.listaAsistentesClientes.splice(param.index, 1);
        },
        agregarFilaObservacion() {
            this.datosReport.listaObservaciones.push({
                numeroObservacion: '',
                procesoAuditado: '',
                numeroClausula: '',
                descripcion: '',
            })
        },
        quitarFilaObservacion(param) {
            let me = this;
            me.datosReport.listaObservaciones.splice(param.index, 1);
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },

    async mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosReport.idCliente = user.uidClient;
            // this.listarClientes();
            if (this.$route.params.id) {
                this.obtenerReport();
            }
        }

    }

}
</script>

<style>
.disabledTab {
    pointer-events: none;
    /* background:rgba(37, 37, 37, 0.048); */
}

.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
    cursor: not-allowed !important;
    background-color: #ffffff !important;
}
</style>
